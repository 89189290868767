
import { defineComponent, onMounted, ref } from "vue";
import { Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import base from "@/api/base.js";
import ElConfigProvider from "element-plus";
import ko from "element-plus/lib/locale/lang/ko";

interface NewAddressData {
  targetTitle: string;
  assign: string;
  dueDate: string;
  targetDetails: string;
  tags: Array<string>;
}

export default defineComponent({
  name: "check-items-detail",
  components: {
    Field,
    Form,
    ElConfigProvider,
  },
  data() {
    return {
      checkItem: { detail: [] },
      qscList: [{}],
      detailCheckItemVersions: {},
      disabledDate(time) {
        const nowDate = new Date();
        const yesterDate = nowDate.getTime() - 1 * 24 * 60 * 60 * 1000;
        return yesterDate > time.getTime();
      },
      sessionId: "",
      ko,
    };
  },
  // props: { id: String },

  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);

    onMounted(() => {
      setCurrentPageBreadcrumbs("Settings", ["Account"]);
    });

    const targetData = ref<NewAddressData>({
      targetTitle: "",
      assign: "",
      dueDate: "",
      targetDetails: "",
      tags: ["important", "urgent"],
    });

    return { targetData };
  },
  mounted() {
    this.sessionId = sessionStorage.getItem("id");
    this.getData();
  },
  methods: {
    getData() {
      base
        .getCheckVersion(this.sessionId)
        .then((res) => {
          console.log(res);
          // this.detailCheckItemVersions = res.data.responseObject;
          this.detailCheckItemVersions = res.data;
          console.log("디테일버전", this.dsetailCheckItemVersions);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async submitForm() {
      const edit = {
        id: this.detailCheckItemVersions.id,
        check_ver_ty: this.detailCheckItemVersions.check_ver_ty,
        check_ver_start_date: this.detailCheckItemVersions.check_ver_start_date,
        check_ver_end_date: this.detailCheckItemVersions.check_ver_end_date,
        check_ver_use_yn: this.detailCheckItemVersions.check_ver_use_yn,
      };
      console.log(edit);
      base
        .updateCheckVersion(edit)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            alert("점검 기준을 수정했습니다.");
            window.history.back();
          } else {
            alert(res.status);
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },
  },
});
